<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="12">
                <MyCard>
                    <template v-slot:header>Configuration Templates</template>
                    <div class="text-body-2">
                        These configuration templates will help you apply the configuration to your edge routers but must be
                        double-checked and updated to suit your network configuration. The templates are dynamically updated with
                        the peer configuration but any interface names require manual update.
                    </div>
                    <!-- <div class="text-subtitle-2">BGP Peer</div> -->
                    <v-row align="center">
                        <v-col cols="6">
                            <v-select
                                label="BGP Peer"
                                placeholder="Select BGP peer"
                                item-text="name"
                                item-value="guid"
                                @change="peerUpdated"
                                :items="peers"
                                v-model="peer_guid"
                                :value="peer_guid"
                                hint="Templates will be rendered for the selected BGP peer."
                                persistent-hint
                                no-data-text="you have no BGP peers"
                                width="50%"
                            />
                        </v-col>
                        <v-col cols="6">
                            <!-- <div class="text-subtitle-2">Router platform</div> -->
                            <v-select
                                placeholder="Select router platform"
                                item-text="name"
                                item-value="value"
                                label="Router platform"
                                @change="peerUpdated"
                                :items="platforms"
                                v-model="selectedPlatform"
                                no-data-text="this is embarrassing but there is no data here"
                                width="50%"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <code v-if="template.length" class="transparent">
                                <span v-for="(line, index) in template" :key="index">{{ line }}<br /></span>
                            </code>
                        </v-col>
                    </v-row>
                </MyCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "@vue/composition-api";
import { useRoute } from "vue2-helpers/vue-router";

import MyCard from "@/components/MyCard.vue";
import axios from "axios";

export default {
    props: {},
    components: {
        MyCard,
    },
    setup() {
        const { emit } = getCurrentInstance();

        const peers = ref([]);
        const peer_guid = ref("");
        const template = ref([]);
        const selectedPlatform = ref("");

        const platforms = [
            { name: "Cisco IOS", value: "cisco_ios" },
            { name: "Juniper JUNOS", value: "juniper_junos" },
            { name: "BIRD2", value: "bird2" },
            { name: "RouterOS (Mikrotik)", value: "routeros" },
        ];

        const route = useRoute();

        function peerUpdated() {
            if (peer_guid.value === "" || selectedPlatform.value === "") return;
            axios.get(`/api/v1/templates/${peer_guid.value}/${selectedPlatform.value}`).then((response) => {
                template.value = response.data;
            });
        }

        onMounted(() => {
            emit("breadcrumb", [
                {
                    text: "Templates",
                    disabled: false,
                    href: "/templates",
                },
            ]);
        });


        axios.get("/api/v1/peers/").then((response) => {
            peers.value = response.data;
            if (route.params.peer_guid) {
                peer_guid.value = route.params.peer_guid;
            }
        });

        return {
            peer_guid,
            peers,
            selectedPlatform,
            peerUpdated,
            platforms,
            template,
        };
    },
};
</script>
